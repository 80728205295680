import React from 'react';
import AgreementHistory from "../AgreementHistory/AgreementHistory";
import SearchDocumentPositions from "../SearchDocumentPositions/SearchDocumentPositions";
import SearchDocument from "../SearchDocument/SearchDocument";

const SearchDocumentView = ({document, positions, agreementHistory}) => {
    return (
        <>
            <SearchDocument data={document}/>
            <div className={'document-all-details-container'}>
                <SearchDocumentPositions data={positions}/>
                <AgreementHistory data={agreementHistory}/>
            </div>
        </>
    );
};

export default SearchDocumentView;